@media screen and (max-width: 500px) {

    .MuiDialogTitle-root {
        padding: 2px !important;
        &.dialog_title {
            padding: 5px !important;
        }
    }

    .MuiDialog-paperWidthLg {
        min-width: 100% !important;
    }

    div.MuiPaper-root.MuiDialog-paper {
        width: 100vh !important;
        margin: 0px !important;
    }

    div.MuiDialogActions-root.dialog_actions {
        margin: 0px !important;
    }

    #root>div>div>div.navigation_container>header>div>div>p:nth-child(2)>b {
        position: absolute;
        top: -10px;
        right: 5px;
    }

    #root>div>div>div.navigation_container>header>div>div>p:nth-child(2)>span {
        display: none;
    }

    #root>div>div>div.navigation_container>header>div>div>div {
        display: none;
    }

    #configCrossedTable>div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16 {
        width: calc(100% - 70px);
    }

    #root>div>div>div.navigation_container>header>div>div>p:nth-child(4) {
        display: none;
    }

    .connexion_button,
    .loginCard_content_form {
        position: relative !important;
        right: unset !important;
        margin: 0 auto !important;
    }

    .loginCard_content_form {
        padding: 15px !important;
        width: unset !important;
    }

    .info_panel {
        display: none !important;
    }
}