.home_container{
    min-height: calc(100vh - 76px);
    display: flex;
    .widgets_container{
        width: 100%;
        float: right;
        background-color: #FFFFFF;
        .title{
            font-weight: 500;
            color: black;
        }
    }
    .calendar_container{
        width: 455px;
        background-color: #F0F0F0;
        float: right;
        .title{
            margin-bottom: 20px;
            line-height: 1.125rem;
        }
    }
}
.react-grid-item-content{
    // padding: 10px !important;
    height: 100%;
    overflow: hidden;
}

span.react-resizable-handle.react-resizable-handle-se {
    position: absolute;
    right: 0;
    bottom: 0;
}