@import "./colors.scss";
@import "./baseTheme.scss";

.login{
    min-height: 100vh;
    background-color: #F0F0F0;
}
.loginFailed{
    margin-top: 50vh;
    transform: translateY(-50%);
    margin-left: auto;
    width: 536px;
    &_content{
        text-align: center;
        padding-top: 35px;
        &_img{
            width: 40%;
        }
        &_title{
            font-size: 28px;
             margin-bottom: 16px;
        }
        &_description{
            color: grey;
            margin: 0 74px;
            font-size: $mainFontSize;
        }
        &_form{
            padding: 40px 71px 0px 71px;
            &_texfield{
                margin: 8px;
            }
            &_checkbox{
                color: grey;
            }
            &_forget{
                padding-top: 8px;
                text-align: left;
                a{
                    text-decoration: none;
                    color: grey;
                }
            }
            &_buttonContainer{
                margin-top: 23px;
                margin-bottom: 23px;
                float: right;
                padding-left: 2px;
                width: 100%;
                button{
                    padding: 6px 14px;
                    position: absolute;
                    right: 85px;
                }
            }
        }
    }
}
.loginCard_container {
    display: flex;
    justify-content: center;
    align-items: center;
    & .sso {
        justify-content: end;
    }
}
.loginCard{
    width: 536px;
    height: 536px;
    &_content{
        text-align: center;
        padding-top: 35px;
        &_img{
            width: 50%;
            margin-top: 34px;
        }
        &_title{
            font-size: 28px;
             margin-bottom: 16px;
        }
        &_description{
            color: grey;
            margin: 0 74px;
            font-size: $mainFontSize;
        }
        &_form{
            padding: 40px 71px 0px 71px;
            &_texfield{
                margin: 8px;
            }
            &_checkbox{
                color: grey;
            }
            &_forget{
                padding-top: 8px;
                a{
                    text-decoration: none;
                    color: grey;
                }
            }
            &_buttonContainer{
                margin-top: 23px;
                margin-bottom: 23px;
                // float: right;
                // padding-left: 2px;
                justify-content: space-between;
                width: 100%;
                // button{
                //     padding: 6px 14px;
                //     position: absolute;
                //     right: 85px;
                // }
            }
        }
    }
}
.regularText{
    font-size: $mainFontSize !important;
}

.errorText{
    color: $errorcolor;
}

.MuiInputAdornment-positionStart,
.MuiInputAdornment-positionEnd {
    margin-left: $mainMargin/2 !important;
    margin-right: $mainMargin/2 !important;
}