
td.edit-cell-VertexAutocomplete {
    height: calc(1.125em + 16px) !important;

    .MuiInputBase-adornedEnd {
        min-height: unset !important;
    }

    input.MuiInputBase-input {
        margin-top: 1px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.54);
        min-height: unset !important;
        height: calc(1.125em + 16px);
        color: #464646 !important;
    }

    .not-valid input.MuiInputBase-input {
        border-bottom: 1px solid #f31700;
    }

    fieldset {
        border: 0px;
    }
} 