@import "../styles/baseTheme.scss";

.k-pivotgrid {
    max-height: calc(100vh - 120px)
}

.k-pivotgrid>.k-pivotgrid-column-headers {}

.k-pivotgrid-row-headers table.k-pivotgrid-table {
    table-layout: auto;
}

.k-pivotgrid-column-headers col,
.k-pivotgrid-values col {
    width: 150px;
}

td.k-pivotgrid-cell,
th.k-pivotgrid-cell {
    padding: 0px;
    padding-left: $mainMargin;
    padding-right: $mainMargin;
}

td.k-pivotgrid-cell:not(.k-pivotgrid-header-root) {
    text-align: right;
}

tr.k-pivotgrid-row {
    th.k-pivotgrid-header-root.k-pivotgrid-expanded {
        width: 0px !important;
        font-size: 0;
        > span {
            margin: 0px;
            padding: 0px;
        }
    }
}

.pivot-grid-configurator-button-row {
    margin-bottom: 70px !important;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 16px;
    border-top: 1px solid rgb(207, 207, 207);
    box-shadow: 0px -2px 3px rgb(207, 207, 207);
}

.pivot-grid-last-level-cell {
    cursor: pointer;
}

.pivot-grid-grand-total-row-header,
.pivot-grid-grand-total-cell,
.pivot-grid-grand-total-col-cell {
    font-weight: bold !important;
    background: #fafafa;
}
.k-grid tbody tr:hover>.pivot-grid-grand-total-col-cell,
.k-grid tbody tr:hover>.pivot-grid-grand-total-cell {
    background: rgba(237, 237, 237, 255) !important;
}

th.k-table-th.k-header:has(> .pivot-grid-grand-total-col-cell) {
    background: #fafafa;
}

.pivot-grid-configurator-button-row .custom_btn_primary_cancel {
    margin-right: 5px !important;
}

.pivot-grid-total-cell {
    font-weight: bold !important;
}
.pivot-grid-grand-total-row-header,
.pivot-grid-grand-total-cell {
    position: sticky !important;
    bottom: 0 !important;
}

.pivot-grid-grand-total-row-header {
    z-index: 4;
}

.pivot-grid-grand-total-cell {
    z-index: 3;
}