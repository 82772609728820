
.k-widget.k-dateinput {
    display: flex;
    .prefix-text, .k-maskedtextbox.k-input {
        display: inherits;
        background-color: transparent;
        border: 0px;
    }

    input.k-input {
        padding-left: 5px;
        padding-right: 0px;
    }
}

span.k-input.k-datepicker.k-input-md.k-rounded-md.k-input-solid.content-simpledatepicker  {
    background-color: transparent;
    border: 0px;

    input {
        padding-left: 5px;
    }
}