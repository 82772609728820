@import './baseTheme.scss';

.k-grid-table {
    tr.k-grouping-row {
        > td {
            padding-top: 0px;
            padding-bottom: 0px;
            background-color: #79797918;
        }
    }
}

.k-grouping-header-flex::before {
    content: unset !important;
}

.k-grid .k-grouping-row .k-icon {
    margin-left: 0px;
}

.k-grouping-header.k-grouping-header-flex {
    align-items: center;
    height: $mainHeight;
    padding: 0px;
    padding-left: $mainMargin;

    .k-indicator-container {
        display: flex;
        padding: 0px;
        margin: 0px;
    }

    .k-group-indicator {
        padding: 0px;
        padding-left: $mainMargin;
        padding-right: $mainMargin / 2;
        > button {
            margin-left: 0px;
            height: 18px;
            > span {
                height: 18px;
            }
        }

        .k-link {
            color: $primarycolor !important;
            span.k-i-sort-asc-sm {
                display: none;
            }
        }

        .k-button-icon.k-icon.k-i-close {

            font-size: 1rem !important;
            font-weight: bolder;
        }
    }
}