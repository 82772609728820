@import './chatBubble.scss';

.bubble-dialog {
    position: absolute;
    z-index: 10000 !important;

    bottom: 12px;
    right: 12px;
    background: #03294f;
    color: white;
    border-radius: 200px;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 2px 2px 5px rgb(143, 143, 143);
    cursor: pointer;

    .bubble-dialog-content {
        transition: all 0.8s ease;
        transform: translate(-50%, -50%);
        box-shadow: 2px 2px 5px rgb(143, 143, 143);
        border-radius: 5px;

        &.bubble-visible {
            opacity: 1;
            visibility: visible;
        }

        &.bubble-hidden {
            opacity: 0;
            visibility: hidden;
            width: 0 !important;
            height: 0 !important;
            .k-chat {
                display: none;
            }

            .bubble-dialog-close {
                display: none;
            }
        }

        .bubble-dialog-close {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #03294f;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .k-chat {
            border-radius: 5px;
        }
    }
}

