@import "../../../../../styles/baseTheme.scss";

$heightOfPicker: $mainHeight * 4;

.tcd-dimensions-picker-container {
    padding-top: $mainMargin !important;
    padding-left: 0px !important;
    height: auto !important;
}

tr.k-pivotgrid-row {

    /** Header **/
    th.k-pivotgrid-cell.k-pivotgrid-header-root,  th.k-pivotgrid-cell {
        > span {
            font-size: 10px !important;
        }

    }

}

.tcd-dimensions-picker {

    .k-grid-content {
        overflow: auto;
        overflow-y: auto !important;
    }

    .add-indicateur-button {
        background-color: unset !important;
        width: 100%;
    }

    .indicateurs-grid {
        height: $heightOfPicker;
        border: 0px !important;
    }

    .k-grid-header {
        display: none;
    }

    tr.k-master-row {
        > td {
            border: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: $mainHeight;
            padding-right: 0px ;
        }
    }

    td.cell-disable {
        color: rgba(0, 0, 0, 0.26) !important;
        span {
            color: rgba(0, 0, 0, 0.26) !important;
        }
    }
}