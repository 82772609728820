@import 'colors.scss';

.sub_list_style {
  height: calc(100vh - 178px);
  position: relative;
  overflow: hidden;

  &.link {
    height: calc(100vh - 253px);
  }
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.link_container {
  display: flex;
  background: #efefef;
  position: relative;
  overflow: hidden;
  width: 100%;

  .k-input-solid {
    background-color: transparent;
  }

  .view-actions-container {
    position: absolute;
    right: 10px;
  }
  .auto-link-component {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .k-slider .k-draghandle, .k-slider .k-slider-selection  {
      background-color: $modificationcolor !important;
      border-color: $modificationcolor_background !important;
    }
    .k-slider .k-slider-track {
      background-color: $modificationcolor_background
    }
    .search-button {
      margin-left: auto;
      min-width: 115px;
    }
  }

  .k-dateinput.k-input input,
  span.k-input input {
    padding: 3px 0 !important;
  }

  .k-textbox input, .k-input input, .k-textbox input, .link-datePicker,
  input.k-textbox, input.k-input, input.k-textbox, .link-datePicker {
    height: 25px !important;
    line-height: 18px;
    font-size: 0.875rem;
    &.content-simpledatepicker {
      bottom: 10px;
    }
  }
  .link-input, .k-dropdown-wrap {
    width: 100%;
    height: 25px;
  }
  select {
    display: inline;
  }
  li.k-item {
    padding-left: 10px;
  }
  .link-title-header {
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
  }
  .link_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
  }
  .list {
    h2 {
      margin-top: 0;
      text-align: center;
    }
    .selecters {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .filters {
      align-items: center;
      display: flex;
      > input {
        :first-child{
          margin-left: initial;
        }
        margin-left: 10px;
      }
      > div {
        display: flex;
        > span {

        }
      }
    }

    .sub_list_style {

    }
    &.referentiel {
      float: left;
    }
    &.vues {
      float: right;
    }
  }
  .modality {
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    >button.unlink {
      background-color: transparent;
      margin-left: 10px;
      cursor: pointer;
      height: 20px;
      float: right;
      &:hover{
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  .iconlink-unlink {
    vertical-align: bottom;
    margin: 0 0 0 10px;
    padding-top: 3px;
    float: right;
    font-size: 20px;
    &.left {
      margin: 0 10px 0 0;
      float: left;
    }
  }
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
}

.link-datePicker {
  height: 30px;
}

.list-links-column {
  width: 33%;
}

.header-list {
  padding-top: 5px;
  height: 90px;
  position: relative;

  &.link {
    position: relative;
    height: 165px;
    overflow: hidden;
  }
}
.link-checkbox-filter {
  margin-left: 10px;
}

.is-text-selected, .is-selected  {
  color: $dark-blue;
  font-weight: 500 !important;
}

.is-linked-auto, .is-linked-auto td  {
  color: $modificationcolor;
  font-weight: 500;
}

.is-linked, .is-linked td, .k-list li.k-item.is-linked {
  color: $dark-green;
  font-weight: 500;
}

.link_container .li:hover {
  background: #b9b9b9ad;
}

.unlink-button {
  width: 36px;
  height: 28px;
  margin-left: 10px;
}

.unlink-button-container {
  display: none;
  position: absolute;
  right: 10px;
  z-index: 100;
}

tr:hover .unlink-button-container {
  display: block;
}

.link-loader-container, .link-loader-div {
  position: absolute;
  width: 100%;
  height: 100%;
}

.link-loader-div {
  background: rgba(87, 87, 87, 0.226);
  z-index: 100;
}

.selected-referential-list-container, .selected-view-list-container {
  position: relative;
}


.item-view-maingrid.k-item.is-selected, .item-view-maingrid.clicked-item {
  display: none;
}

.header-list {
  background: white;
  border: solid 1px rgb(223, 223, 223);
}

.link-action-container {
  position: relative;
}

.buttons-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.button-validation {

}

.iconlink-unlink.link_off {
  visibility: hidden;
}

.link-action-container {
  background: white;
}

.header-list.link_button {
  position: relative;
}

.header-list {
  padding-left: 10px;
  padding-right: 10px;
}

.header-modality-count {
  position: absolute;
  right: 35px;
}

.link-row-header {
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
}

.item-clickable {
  cursor: pointer;
}

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-right, td.align-right {
  text-align: right;
}

td.align-right {
  padding-right: 10px;
}

.k-slider-horizontal {
  height: 6px;
}

.is-inactive td {
  background-color: rgba(255, 0, 0, 0.103) !important;
}