$menu-color: rgba(0, 0, 0, 0.54);

$dark-blue: #009BCE;
$medium-blue: #49B8DC;
$light-blue: #DBF1F8;

$dark-green: rgb(58, 179, 110);
$medium-green: rgb(114, 201, 151);
$light-green: rgb(227, 244, 234);

$dark-grey: rgb(161, 161, 161);
$medium-grey: rgb(205, 205, 205);
$light-grey: rgb(245, 245, 245);

$dark-red: rgb(218, 50, 64);
$medium-red: rgb(229, 109, 119);
$light-red: rgb(250, 226, 228);

$blue-color: #2c6ff6;
$lightblue-color: #e1ecff;
$red-color: #d93021;
$flashred-color: #EC0404;
$orange-color: #F4611D;
$lightred-color: #EC04041A;
$grey-color: #e5e5e5;
$green-color: #4d9a29;
$lightgreen-color: #4d9a29;
$lightblack-color: rgba(0 ,0, 0, 0.6);
$lightgrey-color: rgba(0 ,0, 0, 0.3);
$grey-border: #DDDDDD;
$grey_light_color: #f0f0f0;

$primarycolor: $dark-blue;
$primarycolor_background: #DBF1F8;
$validationcolor:#3AB36E;
$validationcolor_background:  #E3F4EA;
$validationcolor_background_hover: #b7c5bd;
$informationcolor: #1A81EF;
$informationcolor_background: #DEEDFD;

$modificationcolor: #F27629;
$modificationcolor_background: #FDEBE0;
$modificationcolor_background_hover: #d1c2b8;

$errorcolor: #DA3240;
$errorcolor_background: #FAE2E4;
$bordercolor: #DDDDDD;
$backgroundcolor: #F0F0F0;

// #1A81EF #5BA5F4 #DEEDFD
// $selectioncolor: #5BA5F4;
$selectioncolor: #1A81EF;
$selectioncolor_background: #DEEDFD;

$superviseurcolor: #FF00F0;
$datamanagercolor: #eb7f7f;

.picto {
    &.primary_color {
        @extend .custom_btn_secondary;
    }
}

.primary_color {
    color: $primarycolor;
}

.warning_color {
    color: $modificationcolor;
}

.selected-items-container {

    .k-badge-container {
        margin-right: 10px;
        margin-top: 5px;
        color: $menu-color;
    }
    .k-badge {
        transform: translate(50%, -35%);
        font-size: 0.8rem;
        color: white;
        background-color: $dark-blue;
        border-color: $dark-blue;
    }
}

.custom_btn {
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    color: white !important;

    &:not(.picto):not(a):hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    &_danger {
        &:hover {
            background-color: $medium-red !important;
        }
    }

    &_primary {
        @extend .custom_btn;
        background-color: $primarycolor !important;
        &:hover {
            background-color: $medium-blue !important;
        }

        &_validation {
            @extend .custom_btn;
            background-color: $dark-green !important;
            &:hover {
                background-color: $medium-green !important;
            }
        }

        &_cancel {
            @extend .custom_btn;
            background-color: $dark-grey !important;
            &:hover {
                background-color: $medium-grey !important;
            }
        }
    }

    &_secondary {
        @extend .custom_btn;
        color: $primarycolor !important;

        // &:hover {
        //     color: $medium-blue !important;
        // }

        &.Mui-disabled {
            background-color: transparent !important;
            &:hover {
                background-color: transparent !important;
            }
        }

        &.picto {
            &:hover {
                box-shadow: none !important;
                background-color: $light-blue !important
            }
            &:not(:hover) {
                background-color: transparent !important;
            }
        }

        &_validation {
            @extend .custom_btn;
            color: $dark-green !important;
            &:hover {
                // color: $medium-green !important;
                background-color: $light-green !important;
            }
        }

        &_cancel {
            @extend .custom_btn;
            color: $dark-grey !important;
            &:hover {
                // color: $medium-grey !important;
                background-color: $light-grey !important;
            }
        }

        &_remove {
            @extend .custom_btn;
            color: $dark-red !important;
            &:hover {
                // color: $medium-red !important;
                background-color: $light-red !important;
            }
        }
    }
}

.adw-upload {
    .k-upload {
        .k-upload-button input {
            width: unset !important;
        }
        .k-upload-button {

            @extend .picto;
            @extend .custom_btn_secondary;
            min-width: unset;
            /*background-color: $primarycolor !important;
            border: none;
            &:hover {
                background-color: $medium-blue !important;
            }*/
        }
    }
}

.current-view {

}

.users_table_utilisateur_inactive,
.k-grid tbody tr.k-selected:hover .k-grid-content-sticky,
.k-selected .k-grid-content-sticky:hover,
tr.k-selected > td,
tr.k-selected,
tr.k-selected:hover,
.is-selected,
.is-selected td.k-grid-content-sticky,
tr.is-selected:hover td.k-grid-content-sticky {
  background-color: $light-blue !important;
}



button, a  {

    &.navigation {
        &.is-selected.no-radius {
            box-shadow: -2px 0px 0px $dark-blue inset
        }
        &:hover  {
            // color: $medium-grey !important
        }
    }

    &.is-selected {
        color: $dark-blue;
        &.navigation {
            background-color: $light-blue !important;
        }
        &:hover  {
            background-color: rgba($medium-blue, .4) !important
        }
    }
}


.validation_color{
    color: $validationcolor !important;
}
.error_color{
    color: $errorcolor !important;
}
.error_color_background{
    background-color: $errorcolor_background !important;
}