$colorMessageFromMe: #03294f;
:root {
    --kendo-color-surface: #ffffff; /* Exemple de définition */
  }

.k-chat {
    margin: auto;
    max-width: 500px;
    height: 600px;
    max-height: 100%;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-chat .k-message-list {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.k-chat .k-message-list-content {
    padding-block: var(--kendo-spacing-4, 1rem);
    padding-inline: var(--kendo-spacing-4, 1rem);
    width: 100%;
    box-sizing: border-box;
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.k-chat .k-message-list-content > * + * {
    margin-top: var(--kendo-spacing-4, 1rem);
}

.k-chat .k-message-group {
    max-width: 80%;
    background: none;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    position: relative;
}

.k-chat .k-message-group:not(.k-alt) {
    align-items: flex-start;
    text-align: start;
}

.k-chat .k-message-group:not(.k-alt) .k-message-time {
    margin-inline-start: var(--kendo-spacing-2, 0.5rem);
    inset-inline-start: 100%;
}

.k-chat .k-message-group:not(.k-alt) .k-message-status {
    inset-inline-start: 0;
}

.k-chat .k-message-group:not(.k-alt) .k-first .k-chat-bubble,
.k-chat .k-message-group:not(.k-alt) .k-only .k-chat-bubble {
    border-bottom-left-radius: 2px;
}

.k-chat .k-message-group:not(.k-alt) .k-middle .k-chat-bubble,
.k-chat .k-message-group:not(.k-alt) .k-last .k-chat-bubble {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.k-chat .k-message-group.k-alt {
    align-self: flex-end;
    align-items: flex-end;
    text-align: end;
}

.k-chat .k-message-group.k-alt .k-message-time {
    margin-inline-end: var(--kendo-spacing-2, 0.5rem);
    inset-inline-end: 100%;
}

.k-chat .k-message-group.k-alt .k-message-status {
    inset-inline-end: 0;
}

.k-chat .k-message-group.k-alt .k-first .k-chat-bubble,
.k-chat .k-message-group.k-alt .k-only .k-chat-bubble {
    border-bottom-right-radius: 2px;
}

.k-chat .k-message-group.k-alt .k-middle .k-chat-bubble,
.k-chat .k-message-group.k-alt .k-last .k-chat-bubble {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.k-chat .k-message {
    max-width: 100%;
    margin: var(--kendo-spacing-0\.5, 0.125rem) 0 0;
    position: relative;
    transition: margin .2s ease-in-out;
    outline: none;
}

.k-chat .k-message-time,
.k-chat .k-message-status {
    font-size: smaller;
    line-height: normal;
    white-space: nowrap;
    pointer-events: none;
    position: absolute;
}

.k-chat .k-message-time {
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity .2s ease-in-out;
}

.k-chat .k-message-status {
    margin-top: var(--kendo-spacing-0\.5, 0.125rem);
    height: 0;
    overflow: hidden;
    top: 100%;
    transition: height .2s ease-in-out;
}

.k-chat .k-chat-bubble {
    border-radius: 12px;
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-3, 0.75rem);
    border-width: 1px;
    border-style: solid;
    line-height: 18px;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.k-chat .k-chat-bubble a {
    color: inherit;
    text-decoration: underline;
}

.k-chat .k-message.k-selected {
    margin-bottom: var(--kendo-spacing-4, 1rem);
    border: 0;
    color: inherit;
    background: none;
}

.k-chat .k-message.k-selected .k-message-time {
    opacity: 1;
}

.k-chat .k-message.k-selected .k-message-status {
    height: 1.2em;
}

.k-chat .k-message-error,
.k-chat .k-message-sending {
    margin-bottom: var(--kendo-spacing-4, 1rem);
}

.k-chat .k-message-error .k-message-status,
.k-chat .k-message-sending .k-message-status {
    height: 1.2em;
}

.k-chat .k-avatar {
    margin: 0;
    position: absolute;
}

.k-message-group:not(.k-alt) > .k-avatar {
    inset-inline-start: 0;
    bottom: 0;
}

.k-message-group.k-alt > .k-avatar {
    inset-inline-end: 0;
    bottom: 0;
}

.k-chat .k-avatars .k-message-group:not(.k-no-avatar) {
    padding-inline-start: calc(32px + var(--kendo-spacing-2, 0.5rem));
}

.k-chat .k-avatars .k-message-group.k-alt:not(.k-no-avatar) {
    padding-inline-end: calc(32px + var(--kendo-spacing-2, 0.5rem));
}

.k-author {
    margin: 0;
    font-size: smaller;
    line-height: normal;
}

.k-chat .k-author {
    margin: 0;
}

.k-chat .k-timestamp {
    font-size: smaller;
    text-transform: uppercase;
    text-align: center;
    align-self: stretch;
}

.k-quick-replies {
    display: block;
    max-width: 100%;
}

.k-quick-reply {
    border-radius: 100px;
    margin-inline-end: var(--kendo-spacing-2, 0.5rem);
    margin-block-end: calc(var(--kendo-spacing-2, 0.5rem)/2);
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-3, 0.75rem);
    border-width: 1px;
    border-style: solid;
    line-height: 18px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: inline-block;
    flex: 0 0 auto;
    transition-property: color, background-color, border-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    outline: none;
}

.k-scrollable-quick-replies {
    margin-inline-start: -var(--kendo-spacing-4, 1rem);
    margin-inline-end: -var(--kendo-spacing-4, 1rem);
    padding-inline-start: var(--kendo-spacing-4, 1rem);
    padding-inline-end: var(--kendo-spacing-4, 1rem);
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
}

.k-scrollable-quick-replies::-webkit-scrollbar {
    display: none;
}

.k-scrollable-quick-replies .k-quick-reply {
    margin: 0;
}

.k-scrollable-quick-replies .k-quick-reply + .k-quick-reply {
    margin-inline-start: var(--kendo-spacing-2, 0.5rem);
}

.k-message-box {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: inherit !important;
    border-radius: 0 !important;
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.k-message-box:focus, .k-message-box.k-focus, .k-message-box:focus-within {
    outline: 0;
    box-shadow: none;
}

.k-message-box .k-button {
    border-width: 0;
    flex-shrink: 0;
}

.k-message-box .k-button svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
    display: inline-block;
}

.k-rtl .k-message-box .k-button,
[dir="rtl"] .k-message-box .k-button {
    transform: scaleX(-1);
}

.k-chat .k-card-list {
    margin: var(--kendo-spacing-0\.5, 0.125rem) 0 0;
}

.k-message-group.k-alt + .k-card-list,
.k-message-group.k-alt + kendo-chat-message-attachments.k-card-list {
    align-self: flex-end;
}

.k-chat .k-card-deck {
    max-width: calc(100% + var(--kendo-spacing-4, 1rem)*2);
    box-sizing: border-box;
    margin-inline-start: calc(var(--kendo-spacing-4, 1rem)*-1);
    margin-inline-end: calc(var(--kendo-spacing-4, 1rem)*-1);
    padding: var(--kendo-spacing-4, 1rem) var(--kendo-spacing-4, 1rem) var(--kendo-spacing-4, 1rem);
    overflow: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.k-chat .k-card-deck .k-card {
    width: 200px;
}

.k-chat .k-card-deck-scrollwrap {
    margin-right: calc(var(--kendo-spacing-4, 1rem)*-1);
    margin-left: calc(var(--kendo-spacing-4, 1rem)*-1);
    padding-left: var(--kendo-spacing-4, 1rem);
    padding-right: var(--kendo-spacing-4, 1rem);
}

.k-chat .k-card-deck-scrollwrap > .k-card-deck {
    margin-bottom: -20px;
    padding-bottom: 20px;
}

.k-chat .k-card-deck .k-card-wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    flex: 0 0 auto;
    padding-bottom: 5px;
}

.k-chat .k-card-deck .k-card-wrap .k-card {
    flex: 0 0 auto;
}

.k-chat .k-card-deck .k-card-wrap.k-selected {
    background: none;
}

.k-typing-indicator {
    padding: 0;
    border-radius: 50px;
    display: inline-flex;
    flex-flow: row nowrap;
}

.k-typing-indicator span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex: 0 0 8px;
    background-color: currentColor;
    opacity: .4;
}

.k-typing-indicator span:nth-of-type(1) {
    animation: 1s k-animation-blink infinite 0.3333s;
}

.k-typing-indicator span:nth-of-type(2) {
    animation: 1s k-animation-blink infinite 0.6666s;
}

.k-typing-indicator span:nth-of-type(3) {
    animation: 1s k-animation-blink infinite 0.9999s;
}

.k-typing-indicator span + span {
    margin-left: 5px;
}

@keyframes k-animation-blink {
    50% {
        opacity: 1;
    }
}

.k-chat-toolbar,
.k-chat .k-toolbar-box {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    width: 100%;
    border-width: 1px 0 0;
    box-sizing: border-box;
    flex: none;
    overflow: hidden;
    position: relative;
    gap: var(--kendo-spacing-2, 0.5rem);
}

.k-chat-toolbar::before,
.k-chat .k-toolbar-box::before {
    display: none;
}

.k-chat-toolbar .k-button-list,
.k-chat .k-toolbar-box .k-button-list {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    scroll-behavior: smooth;
}

.k-chat-toolbar .k-button,
.k-chat .k-toolbar-box .k-button {
    flex: none;
}

.k-chat-toolbar .k-scroll-button,
.k-chat .k-toolbar-box .k-scroll-button {
    padding: 0 var(--kendo-spacing-1, 0.25rem);
    height: 100%;
    aspect-ratio: auto;
    position: absolute;
    z-index: 2;
    top: 0;
}

.k-chat-toolbar .k-scroll-button .k-button-icon,
.k-chat .k-toolbar-box .k-scroll-button .k-button-icon {
    min-width: auto;
    min-height: auto;
}

.k-chat-toolbar .k-scroll-button-left,
.k-chat .k-toolbar-box .k-scroll-button-left {
    left: 0;
}

.k-chat-toolbar .k-scroll-button-right,
.k-chat .k-toolbar-box .k-scroll-button-right {
    right: 0;
}

.k-chat {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #424242);
    background-color: var(--kendo-color-surface, #fafafa);
}

.k-chat .k-timestamp {
    color: var(--kendo-color-subtle, #666666);
}

.k-chat .k-author {
    font-weight: bold;
}

.k-chat .k-chat-bubble {
    border-color: var(--kendo-color-surface-alt, #ffffff);
    color: var(--kendo-color-on-app-surface, #424242);
    background-color: var(--kendo-color-surface-alt, #ffffff);
    box-shadow: var(--kendo-elevation-1, 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12));
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    order: -1;
}

.k-chat .k-chat-bubble a {
    color: var(--kendo-color-primary, $colorMessageFromMe);
}

.k-chat .k-chat-bubble a:hover {
    color: var(--kendo-color-primary-hover, #116fa5);
}

.k-chat .k-chat-bubble:hover {
    box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-chat .k-selected .k-chat-bubble {
    box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-chat .k-alt .k-chat-bubble {
    border-color: var(--kendo-color-primary, $colorMessageFromMe);
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, $colorMessageFromMe);
    box-shadow: var(--kendo-elevation-1, 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-chat .k-alt .k-chat-bubble:hover {
    box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-chat .k-alt .k-selected .k-chat-bubble {
    box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-chat .k-quick-reply {
    border-color: var(--kendo-color-primary, $colorMessageFromMe);
    color: var(--kendo-color-primary, $colorMessageFromMe);
    background-color: transparent;
}

.k-chat .k-quick-reply:hover {
    border-color: var(--kendo-color-primary, $colorMessageFromMe);
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, $colorMessageFromMe);
}

.k-chat-toolbar,
.k-chat .k-toolbar-box {
    border-color: inherit;
    color: var(--kendo-color-on-app-surface, #424242);
    background-color: var(--kendo-color-surface, #fafafa);
}
