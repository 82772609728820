$mainFont: 'Rubik', sans-serif;
$mainFontNumber: 'Roboto', sans-serif;
$mainHeight: 28px;
$mainToolIconsWidth: $mainHeight;
$mainDateInputHeight: $mainHeight * 0.525;
$mainFontSize: 12px;
$mainMargin: round($mainHeight * 0.3);
$mainRowMargin: 12px;
$mainPadding: round($mainHeight * 0.3);
$marginDialog: 18px;
$mainBorder: rgba(0, 0, 0, 0.12);
$mainWidthTransition: width 0.2s ease;
$mainMenuColor: rgba(0, 0, 0, 0.6);
$mainToolBarHeight: 64px;
$mainPadding : 12px;