@import "./colors.scss";
@import "./theme.scss";

.custom_btn {
  &_contract {
    background-color: $primarycolor_background !important;
    color: $primarycolor !important;
    text-transform: none !important;
    font-weight: "bold";
    border: 1px solid $primarycolor!important;
    box-shadow: none !important;
    &:hover {
      background-color: $primarycolor_background  !important;
      color: $primarycolor !important;
      border: 1px solid $primarycolor;
    }
  }
  &_information{
    background-color: $validationcolor !important;
    color: white !important;
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_information_white {
    background-color: white !important;
    color: $validationcolor !important;
    text-transform: none !important;
    font-weight: "bold";
    border: 1px solid $validationcolor !important;
    box-shadow: none !important;
    &:hover {
      background-color: white !important;
      color: $validationcolor !important;
      border: 1px solid $validationcolor;
    }
  }
  &_style_selected {
    background-color: $primarycolor_background !important;
    color: $primarycolor !important;
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    padding: 0px !important;
    width: 32px;
    height: 32px;
    margin-right: 20px !important;
    &:hover {
      box-shadow: none !important;
    }
  }
  &_nostyle {
    background-color: transparent !important;
    text-transform: none !important;
    box-shadow: none !important;
    border: none !important;
    &_primary{
      color: $primarycolor !important;
      text-transform: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      &:hover {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
    &_black{
      color: black !important;
      text-transform: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      padding: 0px !important;
      width: 32px;
      height: 32px;
      margin-right: 20px !important;
      &:hover {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
  &_secondary:not(.Mui-disabled) {
    background-color: $grey_light_color !important;
    color: $primarycolor !important;
  }
  &_secondary {
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_modification {
    background-color: $grey_light_color !important;
    color: $modificationcolor !important;
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_modification_filled {
    background-color: $modificationcolor !important;
    color: white !important;
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_secondary_black {
    background-color: $grey_light_color!important;
    color: #000 !important;
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_secondary_blue {
    background-color: $grey_light_color !important;
    color: $primarycolor !important;
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_danger {
    background-color: $errorcolor !important;
    color: white !important;
    text-transform: none !important;
    font-weight: "bold";
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_secondary_white {
    background-color: white !important;
    color: $primarycolor !important;
    text-transform: none !important;
    font-weight: "bold";
    border: 1px solid $primarycolor !important;
    box-shadow: none !important;
    &:hover {
      background-color: white !important;
      color: $primarycolor !important;
      border: 1px solid $primarycolor;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
  &_secondary_white_no_border {
    background-color: white !important;
    color: $primarycolor !important;
    text-transform: none !important;
    font-weight: "bold";
    // border: 1px solid $primarycolor !important;
    box-shadow: none !important;
    &:hover {
      background-color: white !important;
      color: $primarycolor !important;
      // border: 1px solid $primarycolor;
    }
  }
  &_reset_primary {
    background-color: transparent !important;
    color: $primarycolor !important;
    text-transform: none !important;
    font-weight: "bold";
    border: none !important;
    box-shadow: none !important;
    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
    }
  }
  &_reset {
    background-color: none !important;
    color: $errorcolor !important;
    text-transform: none !important;
    font-weight: "bold";
    border: none !important;
    box-shadow: none !important;
    &:hover {
      background-color: transparent !important;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.popover_button {
  border: none !important;
  box-shadow: none !important;
  display: none;
}

.draggable_buttons {
  position: absolute;
  top: 38 + $mainMargin;
  right: $mainMargin;
  z-index: 10;
}

.remove_buttons {
  position: absolute !important;
  top: $mainMargin;
  right: $mainMargin;
  z-index: 10;
}

.button_group_disabled {
  cursor: not-allowed !important;
  box-shadow: none !important;
  .Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.validation-button {
  background-color: $validationcolor !important;
}

button:not(.MuiIconButton-root).Mui-disabled,
.picto.custom_btn_primary.Mui-disabled,
.picto.custom_btn_secondary.Mui-disabled,
.picto.primary_color.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    cursor: not-allowed !important;
    pointer-events: all !important;

    &:hover() {
      @extend button.Mui-disabled;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
}

.MuiIconButton-root.Mui-disabled{
    
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
}