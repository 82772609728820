@import url("https://fonts.googleapis.com/css?family=Dosis");
@import "./colors.scss";
@import "./baseTheme.scss";

$global-color: #616161;
$global-color-black: #000000;
$header-color: rgb(17, 17, 17);
$background-color-body: #ffffff;
$background-color-light-4: #bbdefb;
$background-color-light-3: #90caf9;
$background-color-main: #2196f3;
$background-color-dark: #1e88e5;
$animation-delay: 0.5s;
$primarycolor_background_alt: rgb(180, 220, 237);

.calendar-rectangle {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  color: $global-color;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  overflow: hidden;
  border-radius: 8px;

  &:before {
    content: "";
    display: block;
    padding-top: 120%;
  }
}

.calendar-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.main-calendar {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: $background-color-body;
  padding: 32px 10px;

  .calendar-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 10%;
    color: $header-color;

    .title-header {
      width: 100%;
      height: 70%;
      white-space: nowrap;
      font-size: 1.2em;
      // margin-bottom: 10px;
      background-color: $background-color-body;

      @media (min-width: 992px) {
        font-size: 1.4em;
      }

      @media (min-width: 1200px) {
        font-size: 1.2em;
      }

      .header-text {
        flex: 5;
        display: flex;
        height: 100%;

        .today-label {
          flex: 1;
          font-size: 0.8em;

          &:hover {
            cursor: pointer;
            color: $primarycolor;
            background-color: transparent;
          }
        }

        .month-label {
          flex: 3;
        }
      }
    }

    .days-header {
      width: 100%;
      height: 20%;
      background-color: $background-color-body;
      color: rgb(158, 158, 158);
    }

    .button-container {
      width: 100%;
      height: 30%;
      background-color: $background-color-main;

      .event-button {
        flex-grow: 1;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #fff;
          color: $background-color-main;
        }
      }
    }
  }

  .days-container {
    width: 100%;
    height: 80%;
    background: $background-color-body;

    .week {
      height: 15%;
    }
  }
}

//End of calendar container

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  //grow in parent (child property)
  flex-grow: 1;
  //parent properties
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  transition: all 0.4s ease-in-out 0s;

  &.arrow {
    color: $primarycolor;
    font-size: 25px;
    font-weight: bold;

    &:hover {
      background-color: white;
      cursor: pointer;
      color: $primarycolor_background;
      transition: all 0.2s ease-in-out 0s;
    }
  }
}

.day {
  //grow in parent (child property)
  //flex-grow: 1;
  //parent properties
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: $mainHeight;

  .day-number {
    width: $mainHeight;
    height: $mainHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $background-color-body;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &:hover {
    .day-number {
      cursor: default;
      background-color: $primarycolor_background;
      color: $primarycolor;
      transition: background-color 0.2s ease-in-out 0s;
    }
  }

  // &.today {
  //   .day-number {
  //     // background-color: #2c6ff6;
  //     // color: $background-color-body;
  //     //  border: 1px solid #2c6ff6;
  //   }
  // }
  &.hasNexDay {
    .day-number {
      border-radius: 50% 0 0 50%;
      width: 100%;
      border-right: 0;
    }
  }

  &.hasPreviousDay {
    .day-number {
      border-radius: 0 50% 50% 0;
      width: 100%;
      border-left: 0;
    }
  }

  &.isBetween {
    .day-number {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      width: 100%;
    }
  }

  &.has-events {
    .day-number {
      // color: $background-color-dark;
      // font-weight: bold;
      background-color: $validationcolor;
      color: $background-color-body;
    }
  }

  &.has-events.personnal {
    .day-number {
      // color: $background-color-dark;
      // font-weight: bold;
      background-color: $informationcolor;
      color: $background-color-body;
    }
  }

  &.has-events.webinars {
    .day-number {
      // color: $background-color-dark;
      // font-weight: bold;
      background-color: $modificationcolor;
      color: $background-color-body;
    }
  }

  &.has-events.survey_data {
    .day-number {
      // color: $background-color-dark;
      // font-weight: bold;
      background-color: $errorcolor;
      color: $background-color-body;
    }
  }

  &.has-events.market {
    .day-number {
      // color: $background-color-dark;
      // font-weight: bold;
      background-color: $validationcolor;
      color: $background-color-body;
    }
  }

  &.has-events.market {
    .day-number {
      // color: $background-color-dark;
      // font-weight: bold;
      background-color: $validationcolor;
      color: $background-color-body;
    }
  }

  &.has-events.publications_tsm {
    .day-number {
      // color: $background-color-dark;
      // font-weight: bold;
      background-color: $primarycolor_background_alt;
      color: #616161;
    }
  }

  &.selected {
    .day-number {
      border: 1px solid $primarycolor !important;
      background-color: $primarycolor_background !important;
      color: #616161 !important;
    }
  }

  &.selected.publications {
    .day-number {
      border: 1px solid $primarycolor_background !important;
      background-color: $primarycolor !important;
      color: white !important;
    }
  }

  &.selected:hover {
    .day-number {
      color: #616161 !important;
    }
  }

  &.selected.publications:hover {
    .day-number {
      color: white !important;
    }
  }

  &.different-month {
    opacity: 0.5;
  }
}

@mixin slide-animation($translate-position) {
  transform: translateX($translate-position);
  transition: all $animation-delay cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

/* time animation */
.time-appear {
  @include slide-animation(-100%);
}

.time-appear {
  &.time-appear-active {
    @include slide-animation(0);
  }
}

.time-enter {
  @include slide-animation(-100%);
}

.time-enter {
  &.time-enter-active {
    @include slide-animation(0);
  }
}

.time-leave {
  @include slide-animation(-100%);
}

.time-leave {
  &.time-leave-active {
    @include slide-animation(0);
  }
}

/* title animation */
.title-appear {
  @include slide-animation(100%);
}

.title-appear {
  &.title-appear-active {
    @include slide-animation(0);
  }
}

.title-enter {
  @include slide-animation(100%);
}

.title-enter {
  &.title-enter-active {
    @include slide-animation(0);
  }
}

.title-leave {
  @include slide-animation(100%);
}

.title-leave {
  &.title-leave-active {
    @include slide-animation(0);
  }
}

.events-calendar {

  // display: flex;
  // flex-wrap: wrap;
  background-color: $background-color-body;
  font-size: 1em;
  // min-height: 200px;
  font-family: "Rubik", sans-serif;
  border-radius: 8px;

  .calendar-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 20%;

    color: $header-color;

    .title-header {
      width: 100%;
      height: 70%;
      white-space: nowrap;
      font-size: 1rem;
      background-color: $background-color-body;
      font-weight: 500;

      // @media (min-width: 992px) {
      //   font-size: 1.4em;
      // }

      // @media (min-width: 1200px) {
      //   font-size: 1.2em;
      // }

      .header-text {
        flex: 5;
        display: flex;
        height: 100%;

        .today-label {
          flex: 1;
          font-size: 0.7em;

          &:hover {
            cursor: pointer;
            color: transparent;
            background-color: $header-color;
          }
        }

        .month-label {
          flex: 3;
        }
      }
    }
  }

  .day-events {
    // position: relative;
    width: 100%;
    height: 70%;
    background-color: $background-color-body;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.1875rem;
    letter-spacing: 0px;

    .event-container {
      width: 100%;
      text-align: center;
      display: flex;
      padding: 0 10px;
      margin-bottom: 5px;
      // &:hover {
      //   cursor: pointer;
      // }

      .animated-time {
        width: 30%;

        .event-time {
          font-family: Helvetica;
          font-size: 0.875rem;
          line-height: 1.3125rem;
          letter-spacing: 0px;
          color: #5c5c5c;
          font-weight: 400;
          text-align: start;
        }
      }

      .animated-time-alt {
        width: 100%;
        font-size: 1rem;

        .event-time {
          font-family: Helvetica;
          font-size: 0.875rem;
          line-height: 1.3125rem;
          letter-spacing: 0px;
          color: #5c5c5c;
          font-weight: 400;
          text-align: start;
        }
      }

      .animated-title {
        width: 70%;

        .event-title {
          font-size: 0.875rem;
          line-height: 1.3125rem;
          letter-spacing: 0px;
          color: #5c5c5c;
          font-weight: 500;
          display: block;
          text-align: left;
        }
      }

      .event-attribute {
        height: 100%;
        display: flex;
        // justify-content: center;
        // align-items: center;
        overflow: hidden;
        box-sizing: border-box;
        padding: 5px;
      }
    }
  }
}

.list_Categories {
  display: flex;
  justify-content: left;
  align-items: center;
  list-style: none;
  padding-left: 10px;
  margin-top: 5px;
  font-size: $mainFontSize;
  margin-bottom: 15px;
  position: absolute;
  bottom: 5px;
}

.list_Category {
  float: left;
  margin-right: 4px;
  padding: 5px 7px;
  border-radius: 30px;
  color: #fff;
}

.pers {
  background-color: $informationcolor;
}

.web {
  background-color: $modificationcolor;
}

.sv {
  background-color: $errorcolor;
}

.mkt {
  background-color: $validationcolor;
}

.pill {
  &_personnal {
    background-color: $informationcolor;
  }

  &_webinars {
    background-color: $modificationcolor;
  }

  &_survey_data {
    background-color: $errorcolor;
  }

  &_market {
    background-color: $validationcolor;
  }
}

.pill_container {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 5px 10px 0px 0px;
  float: left;
}

.no-events {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0px;
  color: #5c5c5c;
  font-weight: 500;
}

.event-description {
  padding-left: 16px;
  letter-spacing: 0px;
  color: #5c5c5c;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
}

.calendar_publications_container {
  min-height: 101%;
  // box-shadow: 3px 3px 6px #00000026;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: black !important;
}

.calendar_publications_container .main-calendar .days-container {
  height: 100%;
}

.calendar_publications_container .main-calendar .days-container .week {
  height: 16%;
}

.publications .days-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.publications .day-number {
  border: none;
}

.publications .month-label {
  font-weight: 500;
}

.publications {
  color: #000000;
}

.calendar_publications_container {
  background-color: #6a6a6a0d;
}

.publications .main-calendar,
.publications .main-calendar .calendar-header .title-header,
.publications .main-calendar .days-container,
.calendar_publications_container .events-calendar,
.calendar_publications_container .events-calendar .day-events {
  background-color: transparent !important;
}

.publications .box.arrow:hover {
  background-color: transparent;
  color: #009BCE;
}

.has-publication-event {
  background-color: #DBF1F8;
}

.calendar-content {

  .duplicated-message {
    background: red;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 5px;
    border-radius: 2px;
  }
}

.tooltip-duplicated-message-row {

  &.has-duplicated {
    background: red;
  }

  width: 10px;
  height: 5px;
  border-radius: 2px;
  margin-right: 3px;
  margin-top: 5px;
}