@import "./baseTheme.scss";

.container-component {
    >label {
        margin-left: -5px;
        padding-right: 5px;
        padding-left: 5px;
    }

    .content-simpledatepicker {
        //TODO temporaire
        margin-right: 5px;
    }

    .custom_daterangepicker {
        display: flex;
        align-items: center;
    }

    .daterangepicker_container {
        .daterangepicker_content {
            display: flex;
            align-items: center;
        }

        width: 100%;
        display: flex;
        align-items: center;
        //height: $mainHeight;

        .k-textbox-container {
            padding-top: 0px;
            display: inline;
        }

        .k-widget.k-maskedtextbox.k-dateinput-wrap.custom-date-input {
            border: 0px;
        }
    }

    .adw-upload {
        margin: $mainMargin;

        .k-upload .k-dropzone,
        .k-upload .k-upload-files {
            margin-top: $mainMargin;
        }
    }
}

.input-date-text {
    padding-top: 0px;
    padding-bottom: 0px;
}

.container-component {
    border: 1px solid rgba(0, 0, 0, 0.23);

    .k-numerictextbox {
        margin-left: $mainMargin / 2;
    }

    .k-input,
    span:not(.k-dropdownlist),
    input,
    input.k-input-inner,
    input.k-input-inner:focus,
    span.k-input:focus,
    span.k-input {
        border-bottom: 0px !important;
        border: 0px !important;
        border-color: transparent !important;
    }

    span.k-dateinput {
        padding-left: 5px;
    }

    &.k-invalid {
        border-color: $errorcolor;

        &:hover {
            border-color: $errorcolor !important;
        }

        .k-widget.k-dateinput .k-maskedtextbox.k-input {
            color: $errorcolor;
        }
    }

    border-radius: 4px;

    &.auto-height {
        height: auto;
    }

    position: relative;
    display: flex;
    align-items: center;
    padding-right: 0px;

    .prefix-text {
        @extend .input-date-text;
        margin-right: 5px;
        display: flex;
        align-items: center;
        color: $menu-color;
        font-size: $mainFontSize;
    }

    .daterangepicker_selector,
    .k-daterangepicker-wrap {
        width: unset !important;

        .k-textbox-container {
            width: unset;

            .k-widget.k-maskedtextbox.k-dateinput-wrap.custom-date-input {
                width: unset;
            }

            input.k-input {
                @extend .input-date-text;
                width: 100px !important;
                height: unset !important;
            }
        }
    }

    .custom_daterangepicker-container {
        width: 100%;

        .k-picker-wrap.k-picker-wrap {
            border: 0px;
        }
    }

    label {
        background-color: white;
    }

    &:not(.Mui-disabled) {
        &:hover {
            @extend .container-component;
            border: 1px solid black;
        }
    }

    &.Mui-disabled {
        .k-maskedtextbox {
            color: $dark-grey;

            input {
                font-weight: normal !important;
            }
        }
    }
}

.container-component:focus-within {
    border-color: rgb(0, 155, 206);

    >label,
    input,
    >span>span.k-input-spinner,
    .custom_daterangepicker>span>button {
        color: rgb(0, 155, 206);

        &::selection {
            color: rgb(255, 255, 255);
            background: rgb(0, 155, 206);
        }
    }
}

.sellsy-box-container {
    display: flex;
    padding: 6px;

    .container-component {
        &:not(:last-child) {
            margin-right: 24px !important;
        }

        padding: 12px !important;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.dashboard-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.4fr 1.3fr 1.3fr;
    gap: 8px 8px;
    height: calc(100vh - 140px);

    .dashboard-block {
        border: 1px solid $grey-border;
        position: relative;
    }

    .dashboard-header {
        grid-area: 1 / 1 / 2 / 3;
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1.4fr 0.7fr 0.7fr 0.1fr;
        grid-template-rows: 1fr;

        .dashboard-header-block {
            display: grid;
            grid-auto-columns: 1fr;
            grid-template-columns: 0.7fr 1.3fr;
            grid-template-rows: 1fr;
            border-right: 1px solid $grey-border;
            position: relative;

            &:last-child {
                border-right: none;
            }

            .dashboard-header-block-title {
                color: $lightblack-color;
            }

            .dashboard-header-block-value {
                font-size: 1.25rem;
                font-weight: 500;
            }

            .dashboard-header-block-additional {
                position: absolute;
                bottom: - 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
            }

            .prefix-text {
                color: $menu-color;
                font-size: $mainFontSize;
            }
        }
    }
}