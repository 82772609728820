@import './colors.scss';

.no-margin {
    margin: 0px !important;

    .k-widget.k-editor {
        margin: 0px !important;
        border: 0px;
    }

    .adw-upload .k-upload {
        border: none;

    }
}

.k-widget.k-editor {
    >.k-widget.k-toolbar {
        >span.k-widget.k-dropdown {
            width: 130px;
        }
    }
}

.adw-upload {
    .k-upload {
        background-color: transparent;
        border: none;
    }
    .k-file-single {
        .k-file-icon-wrapper {
            color: inherit;

        }
        .k-file-info {
            width:100%;
            .k-file-validation-message {
                display: none;
            }
        }
        .k-upload-action {
            color: inherit;
        }
        .k-progress-status {
            display:none;
        }
    }
    .k-upload-status.k-upload-status-total {
        display: none;
    }

    .k-upload .k-progressbar {
        bottom: 0px;
    }

    .k-selected {
        background-color: $dark-blue;
    }
    .k-upload .k-upload-files {
        border: none;
    }
    .k-upload .k-upload-files .k-file {
        padding : 2px;
        padding-bottom: 4px;
    }
    .k-upload .k-dropzone {
        background-color: transparent;
        padding: 0px;
    }
    .k-button-md {
        border: none;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 4px;
        padding-left: 4px;
    }

    .k-widget.k-upload {
        display: flex;
        border: none;
        padding: 0px;
    }

    .k-upload-actions {
        display: flex;
    }
}