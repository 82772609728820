@import './colors.scss';

.k-textbox:focus{
    border-color: $primarycolor !important;
}
.k-dateinput-wrap:focus{
    border-color: $primarycolor !important;
}
.k-text-selection ::selection, .k-block ::selection, .k-panel ::selection, .k-pane-wrapper ::selection, .k-view ::selection, .k-progressbar ::selection, .k-numerictextbox ::selection, .k-flatcolorpicker ::selection, .k-combobox ::selection, .k-dateinput ::selection, .k-datepicker ::selection, .k-datetimepicker ::selection, .k-timepicker ::selection, .k-dropdown ::selection, .k-dropdowntree ::selection, .k-multiselect ::selection, .k-maskedtextbox ::selection, .k-rating ::selection, .k-window ::selection, .k-drawer ::selection, .k-notification ::selection, .k-breadcrumb ::selection, .k-pager-wrap ::selection, .k-panelbar ::selection, .k-card ::selection, .k-splitter ::selection, .k-spreadsheet ::selection, .k-scheduler ::selection, .k-chat ::selection, .k-mediaplayer ::selection, .k-pdf-viewer ::selection, kendo-scrollview.k-scrollview-wrap ::selection, div.k-scrollview ::selection, .k-chart ::selection, .k-sparkline ::selection, .k-stockchart ::selection {
    background-color: $primarycolor !important;
}
.k-focused{
    border-color: $primarycolor !important;
}
.k-textbox-container::after{
    display: block;
    z-index: 2;
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0px;
    right: 0px;
    height: 0px;
    margin: auto;
    opacity: 0;
    transform: scaleX(0.5);
    transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    background-color: $primarycolor !important;
}
.k-textbox-container.k-focused > .k-label, .k-floating-label-container.k-focused > .k-label {
    color: $primarycolor !important;
}
.k-text-error {
    color: $errorcolor !important;
}
.k-calendar.k-calendar-range .k-range-start .k-link, .k-calendar.k-calendar-range .k-range-end .k-link {
    background-color: $primarycolor !important;
}
.k-calendar .k-selected .k-link {
    border-color: $primarycolor !important;
    color: #ffffff;
    background-color: $primarycolor !important;
}
.k-calendar.k-calendar-range .k-range-start, .k-calendar.k-calendar-range .k-range-end, .k-calendar.k-calendar-range .k-range-mid {
    background-image: linear-gradient(transparent 1px, $primarycolor_background 1px, $primarycolor_background calc(100% - 1px), transparent calc(100% - 1px)) !important;
}
.k-calendar.k-calendar-range .k-range-split-start::after {
    left: -5px !important;
    right: auto !important;
    background-image: linear-gradient(to left, $primarycolor_background, transparent 100%) !important;
}
.k-calendar.k-calendar-range .k-range-split-end::after {
    left: -5px !important;
    right: auto !important;
    background-image: linear-gradient(to left, $primarycolor_background, transparent 100%) !important;
}
.k-grid-footer{
    margin-bottom: 5px;
}
// .k-grid-content{
//     height: 100vh;
// }
.k-grid .k-grid-filter, .k-grid .k-header-column-menu, .k-grid .k-hierarchy-cell .k-icon {
    color:$primarycolor;
    font-size: $mainFontSize;
}