@import 'styles/login.scss';
@import 'styles/buttons.scss';
@import 'styles/grid.scss';
@import 'styles/navigation.scss';
@import 'styles/calendar.scss';
@import 'styles/home.scss';
@import 'styles/kendoreact.scss';
@import 'styles/theme.scss';
@import 'styles/colors.scss';
@import 'styles/tabstrip.scss';

.dialog_actions {
  margin-right: 0;
  margin-bottom: 0;

  .cancel {
    // margin-right: $mainMargin !important;
  }

  .MuiDialogActions-spacing> :not(:first-child) {
    margin-left: $mainMargin !important;
  }
}

.dialog_title {
  font-size: 1.3125rem !important;
  font-weight: 500 !important;
  line-height: 1.5625rem !important;
  font-family: 'Rubik' !important;
  padding: 20px 24px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;

  h2 {
    font-size: 1.3125rem !important;
    font-weight: 500 !important;
    line-height: 1.5625rem !important;
    font-family: 'Rubik' !important;
    // margin-left: 20px;
  }
}

.MuiStepIcon-active,
.MuiStepIcon-completed {
  color: $primarycolor !important;
}

#nw_msg_dlg .MuiDialog-paper {
  min-width: 60%;
}

.Mui-focused {
  color: $primarycolor !important;

  .MuiOutlinedInput-notchedOutline {
    border-color: $primarycolor !important;
  }

  .MuiSelect-icon {
    color: $primarycolor !important;
  }
}

.k-datepicker {
  width: 100%;
}

// .k-datepicker .k-select{
//   z-index: 50000;
// }
.k-calendar-container {
  z-index: 9999 !important;
}

.edit-datepicker-inline .k-datepicker {
  width: 100% !important;
}

.edit-datepicker-inline {
  overflow: visible !important;
}

// .k-grid-edit-row .k-calendar-container td{
//   height: 5px !important;
// }
.k-grid-footer {
  margin-bottom: 0px;
}

.k-grid-footer td {
  border-width: 0 0 0 0px !important;
}

#filtersPanelTabs .Mui-selected {
  color: $primarycolor !important;
}

.tab-indicator {
  background-color: $primarycolor !important;
}

.input_fees .MuiInputBase-root {
  height: 100% !important;
}

.input_fees .MuiInputBase-root input {
  padding: 0 !important;
  padding-left: 10px !important;
}

.datagridcomponent-CrossedTable,
.datagridcomponent-Scheduler {
  tr.k-table-row.k-master-row[data-grid-row-index="0"] {
    font-weight: 500;
    // position: sticky;
    // z-index: 1000;
  }
}

.datagridcomponent-CrossedTable {
  tr.k-table-row.k-master-row[data-grid-row-index="0"] {
    // top: 29px;
    // background-color: white !important;
  }
}

.datagridcomponent-Scheduler {
  tr.k-table-row.k-master-row[data-grid-row-index="0"] {
    // top: 101px;
    // background-color: white !important;
  }
}

.Mui-selected {
  color: $primarycolor !important;
}

.chips_container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 5px;
  margin: 0;
}

.chip_item {
  margin: 5px;
}

.base_tarifs .Mui-focused .MuiInputAdornment-root {
  color: $primarycolor;
}

.base_tarifs .MuiInputAdornment-root {
  color: rgba(0, 0, 0, 0.4);
}

.base_tarifs .MuiInputAdornment-root .MuiSvgIcon-root {
  width: 0.9rem;
  height: 0.9rem;
}

.MuiInputBase-root .Mui-focused {
  color: $primarycolor;
}

.treelist_telerik table {}

#about_dialog {
  // min-width: 550px;
  min-height: 400px;
}

#custom_import_messages_dialog {
  max-width: 700px !important;
  min-width: 700px !important;
}

#custom_import_view_messages_dialog {
  max-width: 700px !important;
  min-width: 700px !important;
}

#messages_dialog {
  width: 950px;
}

#messages_dialog_createupdate {
  width: 95%;
  height: calc(100% - 70px);

  .DialogContent-container.messages {
    height: 100%;
  }

  .dialog_actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .message_dialog_content {

    height: calc(100% - 70px) !important;
  }
}

#advanced_filters_dialog {
  min-width: 750px;
}

.error_dateInput {
  .k-dateinput-wrap {
    border-color: $errorcolor !important;

    input {
      color: $errorcolor !important
    }
  }
}

.k-grid tbody tr:hover>.k-grid-content-sticky {
  background: rgba(237, 237, 237, 255) !important;
}

.message_pill_title {

  top: 50%;
  transform: translate(0, -50%);

  line-height: initial;
  background-color: white;
  position: absolute;
  font-size: 1rem !important;
  padding: 2px 5px !important;
  color: $primarycolor !important;
  margin-left: 5px !important;
  border: 1px solid $primarycolor !important;
  border-radius: 8px !important;
}

.advanced_filters_tabs {
  background: $primarycolor !important;
}

.select-filter:before {
  content: none !important;
}

.MuiInput-underline:after {
  border: none !important;
}

.MuiInput-formControl:before {
  content: none !important;
}

.react-calendar {
  border: none !important;
  margin: 0 auto;
}

.ref_Advertisers {
  padding: 8px;
  color: white;
  background-color: lightblue;
  border-radius: 10px;
}

.usertype_Manager {
  padding: 4px;
  color: white;
  background-color: rgb(244, 97, 29);
  border-radius: 10px;
}

.usertype_Utilisateur {
  padding: 4px;
  color: white;
  background-color: rgb(64, 170, 19);
  border-radius: 10px;
}

.usertype_Administrateur {
  padding: 4px;
  color: white;
  background-color: rgb(236, 4, 4);
  border-radius: 10px;
}

.usertype_Contact {
  padding: 4px;
  color: white;
  background-color: rgb(12, 154, 232);
  border-radius: 10px;
}

.usertype_Formateur {
  padding: 4px;
  color: white;
  background-color: rgb(20, 106, 255);
  border-radius: 10px;
}

.usertype_Invité {
  padding: 4px;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
}

.ref_Supports {
  padding: 8px;
  color: white;
  background-color: tomato;
  border-radius: 10px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.dx-toolbar-after {
  right: auto;
}

.agence {
  padding: 8px;
  color: white;
  background-color: yellowgreen;
  border-radius: 10px;
}

.pricing-table .MuiToolbar-regular {
  border-bottom: none;
}

.awssld {
  height: 80%;
  --organic-arrow-thickness: 4px !important;
  --organic-arrow-border-radius: 7px !important;
  --organic-arrow-height: 0px !important;
  --organic-arrow-color: $primarycolor !important;
  --control-bullet-color: $primarycolor !important;
  --control-bullet-active-color: $primarycolor !important;
  --loader-bar-color: $primarycolor !important;
}

.searchTable,
.ReactVirtualized__Grid {
  width: 100% !important;
}

.ReactVirtualized__Table__rowColumn {
  display: table-cell;
  vertical-align: middle;
}

.ReactVirtualized__Table__row {
  display: table !important;
  border-bottom: 1px solid grey;
  width: 100%;
}

.name-search-table {
  width: 70%;
}

.type-search-table {
  width: 10%;
}

.ReactVirtualized__Table__Grid:focus {
  outline: none;
}

#adminPanelTabs .MuiTabs-indicator {
  background-color: $primarycolor;
}

#adminPanelTabs .Mui-selected {
  color: $primarycolor;
}

#usersPanelTabs .MuiTabs-indicator {
  background-color: $primarycolor;
}

#usersPanelTabs .Mui-selected {
  color: $primarycolor;
}

#newContact:focus,
#newUser:focus {
  outline: none;
}

.rightIcon-false {
  color: lightgrey;
}

.rightIcon-true {
  color: $primarycolor;
}

.dx-header-row>td>.dx-treelist-text-content,
.dx-treelist-container .dx-sort-up:before {
  color: white;
}

.dx-treelist-headers,
.dx-header-row {
  background: rgb(51, 51, 51);
  color: white !important;

  font-weight: 400;
  font-family: Oswald;
  text-transform: uppercase;
  text-align: center;
}

.dx-treelist-text-content,
td {
  font-family: "Rubik";
  color: #464646;
}

.dx-page-sizes {
  display: none;
}

.dx-treelist-empty-space {
  width: 10px !important;
}

.dx-treelist .dx-row>td {
  padding: 4px;
}

.treelist-container {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background: white;
  height: 100%;
  overflow: auto !important;
}

#configCrossedTable .MuiDrawer-paper {
  padding: 20px;
}

#admin_users .MuiTableCell-root {
  padding: 2px !important;
}

#adminPanel .MuiTableCell-head {
  border: none;
  font-weight: bold;
  font-size: 0.9rem;
}

#adminPanel .MuiToolbar-root {
  border-bottom: none;
}

#configCrossedTable .MuiDrawer-paper {
  width: 35%;
  padding: 0;
  height: 100%;
}

.rw-select-bordered {
  border-left: none !important;
  color: rgb(226, 226, 226);
  background-color: rgb(226, 226, 226);
}

.rw-select-bordered:hover {
  background-color: rgb(226, 226, 226) !important;
}

.rw-widget-picker input {
  background-color: rgb(226, 226, 226);
}

#select_indicateurs .rw-widget-container {
  padding: 5px;
}

#select_indicateurs .rw-multiselect-tag {
  margin: 0px 2px 0px 2px;
}

#select_indicateurs input {
  height: 100%;
  background-color: white !important;
  margin-left: 2px;
}

.react-datepicker__input-container::after {
  position: absolute;
  right: 5%;
  top: 30%;
  content: url(./utils/calendar.png);
}

.datepicker-separator {
  margin: 0 10px;
}

.input-datepicker {
  padding: 0 0.857em;
  height: 30px;
  box-sizing: border-box;
  border: #ccc 1px solid;
  font-size: 1rem;
  color: #555;

  width: 100%;
}

#configCrossedTable {
  z-index: 1;
}

.k-animation-container {
  z-index: 1700;
}

.k-item {
  .k-animation-container {
    z-index: unset;
  }
}

// #custom_daterangepicker .k-textbox-container {
//   width: 49%;
// }
.k-dateinput {
  width: 100%;
}

#tabed_generic {
  margin-top: 5px;
  margin-left: 5px;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
}

#tabed_generic .Mui-selected {
  color: $primarycolor;
  background: white;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

#tabed_generic .MuiTabs-indicator {
  background-color: white;
}

.addTab_button {
  margin-left: 10px;
}

.addTab_button:hover {
  background-color: transparent;
}

.Support {
  color: rgb(81, 136, 247);
}

.Annonceur {
  color: rgb(115, 178, 78);
}

.search-pannel-btn {
  margin-left: auto !important;
  width: 90% !important;
}

.k-dialog-buttongroup {
  justify-content: center !important;
}

.k-plus::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;
}

.k-minus::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.loader-container {
  z-index: 1000;
  background: transparent;
  width: 100%;
  overflow: hidden;
  // top: 0;
  // left: 0;
  // position: absolute;
}

.MuiAutocomplete-popper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition: opacity 416ms;
  border-radius: 4px;
}

.k-widget.k-numerictextbox {
  width: 100% !important;
}

.discount_mode {
  .MuiFormControlLabel-label {
    margin-inline-start: 4px;
  }
}

.DiscountCategoryArray {
  border-left: none;
  border-right: none;

  .k-grid-header {
    display: none;
  }

  tr {
    height: $mainHeight;
  }
}

.DiscountCategoryArray .k-grid-header {
  padding-inline-end: 0px;
}

.DiscountCategoryArray .k-grid-header th {
  padding-top: 0px;
  //padding-left: 0px;
  padding-bottom: 0px;
  vertical-align: middle !important;
}

.k-grid-header th.numeric {
  justify-content: end;
  padding-right: 8.4px !important;
}

.fees-container {
  .DiscountCategoryArray {
    border-top: none;
    border-bottom: solid 1px rgb(214, 214, 214);

    .k-grid-header {
      display: block;
    }

    .k-header {
      .k-cell-inner {
        padding-right: 12px;
      }
    }

    table.k-table.k-grid-header-table th.k-header {
      padding-left: 12px !important;
    }

    .k-grid-content td {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

.discount-editor-array-container {
  &.barter-container {
    border-top: 0px;
    border-bottom: 0px;
  }
}

.k-widget.k-grid.DiscountCategoryArray {
  border-left-width: 0;
  border-right-width: 0;
}

.DiscountCategoryArray .k-grid-content.k-virtual-content {
  overflow: auto !important;
}

.k-grid-header-sticky {
  background-color: white !important;
  color: rgb(64, 64, 64) !important;
}

.k-grid-content-sticky {
  background-color: white !important;
}

/* .generic-document-container {

} */

.content-tab {
  @extend .grid_panel_container;
  margin-left: 5px;
  background-color: white;
  position: relative;
  overflow: hidden;

  .top-right-items {
    .icons-panel {
      top: 0;
      right: 0px;
    }
  }
}

.k-button.k-button-icon.k-clear-button-visible {
  padding: 0 !important;
  width: 14px !important;
}

.k-select {
  width: 16px !important;
}

.k-filtercell-operator {
  margin-left: 0px !important;
}

.k-grid-header th,
.k-grid-header-sticky th {
  padding-right: 4px !important;
}

.k-filtercell input {
  font-size: $mainFontSize
}

.k-filtercell .k-input {
  width: inherit;
}

.k-grid-content {
  overflow-y: scroll !important;
}

.title-CustomLayout {
  /* border-bottom: 1px solid rgb(220, 220, 220);
  text-align: center; */
  font-weight: 500;
  padding-left: 0.7rem;
  font-size: 1rem;
  line-height: 1.3125rem;

  position: absolute;
  z-index: 10;

  background: white;
}

.subtitle-CustomLayout {
  /* border-bottom: 1px solid rgb(220, 220, 220);
  text-align: center; */
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.3125rem;
}

.row-column-conf-text {}

.k-daterangepicker-wrap {
  width: 100%;
  display: flex !important;
}

.SearchWidgets {
  width: 300px;
}

tr:hover td.k-grid-content-sticky,
tr:hover th.k-grid-header-sticky {
  // background-color: rgb(237,237,237) !important;
}

.k-grid-header .k-header.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky,
.k-grid-content-sticky,
.k-grid-footer-sticky {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

td.add-border-right,
th.add-border-right {
  border-right-width: 1px !important;
  border-right-color: rgba(0, 0, 0, 0.12) !important;
  border-right: solid;
}

.add-border-left {
  border-left-width: 1px !important;
  border-left-color: rgba(0, 0, 0, 0.12) !important;
  border-left: solid;
}

.k-header.k-grid-header-sticky.add-border-left {
  border-left-width: 1px !important;
}

.k-header.k-grid-header-sticky.add-border-right {
  border-right-width: 1px !important;
}


.k-grid-content-sticky,
.k-grid-header-sticky {
  background-color: rgb(250, 250, 250) !important;
}

.no-data-graph {
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.legend-graph {
  position: absolute;
  left: 12px;
  top: 40px;
  z-index: 10;
  font-size: 10px;
  color: #9c9c9c;
  background: transparent;
}

.MuiSwitch-colorSecondary.Mui-checked:hover,
.MuiSwitch-switchBase {
  background-color: transparent !important;
}

.inactive_cell {
  color: #afafaf
}

.users_table {
  &_super-administrateur {
    padding: 6px;
    color: white;
    background-color: $informationcolor;

    &_inactive {
      color: white;
      padding: 6px;
      background-color: $informationcolor_background
    }
  }

  &_administrateur {
    padding: 6px;
    color: white;
    background-color: $primarycolor;

    &_inactive {
      color: white;
      padding: 6px;
      background-color: $primarycolor_background
    }
  }

  &_manager {
    padding: 6px;
    color: white;
    background-color: $validationcolor;

    &_inactive {
      color: white;
      padding: 6px;
      background-color: $validationcolor_background
    }
  }

  &_utilisateur {
    padding: 6px;
    color: white;
    background-color: $modificationcolor;

    &_inactive {
      color: white;
      padding: 6px;
    }
  }

  &_superviseur {
    padding: 6px;
    color: white;
    background-color: $superviseurcolor;

    &_inactive {
      color: white;
      padding: 6px;
    }
  }

  &_dataManager {
    padding: 6px;
    color: white;
    background-color: $datamanagercolor;

    &_inactive {
      color: white;
      padding: 6px;
    }
  }
}

.loader-kendo-container .k-loader {
  color: $primarycolor
}

.MuiBreadcrumbs-separator {
  font-size: 0.9rem;
}

.MuiBreadcrumbs-li {
  font-size: 0.9rem;

  p {
    font-size: 0.9rem;
  }
}

#users_grid_dialog {
  max-width: 800px !important;
}

#referential_dialog {
  min-width: 450px;
}

#referential_dialog_currencies {
  max-width: 450px;
}

#change_pass_dialog {
  max-width: 420px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $primarycolor !important;
}

.MuiRadio-root {
  color: $primarycolor !important;
}

.MuiCheckbox-root {
  color: $primarycolor !important;
}

#master_data_dialog {
  max-width: 420px !important;
}

#new_client_dialog {
  width: 650px !important;
}

.new_client_title_ref_AdvertiserGroups {
  float: left;
  color: white;
  padding: 5px;
  background-color: $primarycolor;
}

.new_client_title_ref_Advertisers {
  float: left;
  color: white;
  padding: 5px;
  background-color: $modificationcolor;
}

.new_client_title_ref_AgencyGroups {
  float: left;
  color: white;
  padding: 5px;
  background-color: $validationcolor;
}

.new_client_title_ref_Agencies {
  float: left;
  color: white;
  padding: 5px;
  background-color: $errorcolor;
}

.customers_tooltip_companies_button {
  padding-left: 0px !important;
}

.customers_tooltip_companies_button:hover {
  background-color: transparent !important;
}

.edit_customer:hover {
  background-color: transparent !important;
}

.box-right {
  padding-left: $mainMargin !important;
}

.by_default_lnk_adwcmp_support {
  padding: 5px 10px;
  background-color: #009BCE;
  color: white;
}

.k-treeview .k-in.k-focused {
  background-color: transparent;
  // color:#009BCE;
}

.k-treeview .k-in:hover,
.k-treeview .k-in.k-hover {
  cursor: pointer;
  background-color: transparent;
  color: #009BCE;
}

.k-treeview .k-in.k-selected {
  background-color: transparent !important;
  color: #009BCE;
}

.treeview_container {
  width: 100%;
  display: flex;
}

.treeview_element {
  padding-right: 30px;

  .treeview_element_name {
    float: left;
  }

  .treeview_element_button {
    // min-width: 50px;
    display: none !important;
    position: absolute;
    right: 0px;
    top: -3px;
  }

  &:hover {
    .treeview_element_button {
      display: block !important;
    }
  }
}

#dialog_setting_currencies {
  width: 500px;
}

#dialog_report_estimates {
  width: 500px;
}

#dialog_setting_currencies .custom_btn_primary {
  margin-top: 32px;
}

.k-gantt .k-treelist-scrollable .k-grid-header th:last-child .k-header {
  padding: 16px 0px;
}

.noleftpx {
  left: 0 !important
}

.k-column-resizer {
  border-right: solid 0.5px #e2e2e2;
  height: 100%;
}

.task-element-override,
.message-element-override {
  position: relative;

  // padding-left: 5px;
  border-radius: 2px;
  z-index: 1;
  // padding-right: 18px;
}

.k-widget.k-toolbar.k-gantt-toolbar.k-gantt-header {
  display: none;
}

.task-element-override,
.message-element-override {
  top: 0px !important;
}

.task-element-override {
  background: #538fc3;
}

.task-element-override-new {
  position: relative;
  top: unset !important;

  &.k-task-milestone-override-new {
    transform: translateX(5px);
  }
}

.message-element-override {
  background: #64aae7;
}

.k-widget.k-toolbar.k-gantt-toolbar.k-gantt-header {
  z-index: 1000;
}

tr.k-filter-row th.k-grid-header-sticky {
  padding-top: 0px !important;
  padding-bottom: 3px !important;
}

.day-granularity-header,
.week-granularity-header,
.month-granularity-header,
.year-granularity-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px;
  vertical-align: middle !important;
  text-align: center !important;


  padding-top: 4px !important;
  padding-bottom: 5px !important;
}

.year-granularity-header {
  padding-top: 6px !important;
}

.hidden-scheduler-text-container {
  opacity: 0.25;
}

.k-gantt {
  .k-gantt-content {

    .scheduler-text-container {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .bar-dates-element {
      margin: 1px;
    }

    .k-grid td {
      // border-right: solid 0.5px #ebebeb !important;
    }

    .k-grid .k-gantt-columns td {
      border-right: solid 0.5px #ffffff00 !important;
    }

    td[role="presentation"] {
      border-bottom-color: #00000000;
    }

    tr.k-master-row {
      // height: 5px!important;
    }

    .scheduler-text-cell {
      font-size: 12px;
      display: flex;
      align-items: center;

      .text-separator {
        position: relative;
        margin-left: 7px;
        margin-right: 5px;

        .k-badge {
          height: 5px;
          width: 5px;
          background-color: unset;
          border-color: unset;
          padding: 0px;
        }

        .k-badge-edge {
          position: relative;
          transform: unset;
          z-index: unset;
        }
      }

      .badge-template {
        position: relative;
        margin-left: 3px;
        border-radius: 0px;

        .k-badge-edge {
          border-radius: 0%;
        }

        .k-badge {
          height: 10px;
          width: 10px;
          background-color: unset;
          border-color: unset;
        }

        .k-badge-edge {
          position: relative;
          transform: unset;
          z-index: unset;
        }
      }
    }
  }
}

.k-grid-content td {
  padding-left: 5px !important;
}

td.k-command-cell {
  padding-left: 0px !important;
  height: $mainHeight - 1;
}

.clear-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0px !important;
}

.custom-granularity-header {
  height: 25px !important;
}

.k-nonwork-hour {
  background-color: #c3c3c329 !important;
}

.k-grid-header {
  .k-header {
    padding-right: 0 !important;
  }
}

.k-grid-header-hidden {
  .k-grid-header {
    display: none;
  }

  .k-grid-content {
    overflow-y: auto !important;
  }
}

.calendar-view .MuiSwitch-root {
  height: 50px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.top-right-items {
  position: absolute;
  right: 0;
  top: 0;
}

.top-right-items-actions {
  float: right;
}

.top-left-items {
  position: absolute;
  left: 0;
  top: 0;
}

td.k-text-wrap {
  white-space: break-spaces !important;
}

.k-grid th,
.k-grid td,
.k-header.k-grid-header-sticky {
  padding-left: 5px;

}

.k-grid td {
  padding-top: 2px;
  padding-bottom: 2px;
}

.k-icon.k-i-none {
  width: 10px;
}

.k-command-cell button {}

.content-task-group {
  padding-left: 5px !important;
}

th.edit-column-cell.k-header.k-grid-header-sticky,
.edit-column-cell.k-grid-content-sticky.k-text-nowrap {
  border-left-color: rgba(0, 0, 0, 0.12) !important;
  border-left-width: 0.5px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.treelist_telerik td {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  max-width: 0px;


}

thead.k-grid-header>tr:not(:only-child)>th {
  border-left: 0px;
  // border-right-width: 1px;
  // border-right-color: rgba(0, 0, 0, 0.12);

}

.k-grid-content-sticky.selection-column-cell {
  padding-right: 0px;
  padding-left: 0px;
}

.k-command-cell {
  padding-right: 1px !important;
}

.remove_range {
  padding-top: 5px;
  width: 100%;
  text-align: center;
  height: 30px;
}

.remove_range span {
  color: $errorcolor;
  cursor: pointer;
  display: none;
}

.remove_range:hover span {
  display: block;
}

.remove_range_container:hover>.remove_range span {
  display: block;
}

.gray-font {
  color: rgb(168, 168, 168);
  font-style: italic;
}

.gray-back {
  background: #79797918;
}

tr:not(:hover) {}

.gray-background {
  background: rgb(242, 242, 242);
  font-weight: 500;
}

.star_cell {
  position: absolute;
  right: 3px;
  top: -1px;
}

.k-grid-edit-row td {
  padding-right: 10px !important;
}

.edit-column-cell.k-grid-content-sticky.k-text-nowrap {
  padding-left: 0px;
}

.k-grid-edit-row td.clear-padding {
  padding-right: 0px !important;
}

.contract {
  width: 100%;
  text-align: center;
  background-color: rgb(240, 240, 240);
  border: 1px solid $bordercolor;
  border-radius: 2px;
}

.contract_selected {
  width: 100%;
  text-align: center;
  background-color: $primarycolor_background;
  border: 1px solid $primarycolor;
  border-radius: 2px;
}

.module {
  width: 100%;
  text-align: center;
  background-color: rgb(240, 240, 240);
  border: 1px solid $bordercolor;
  border-radius: 2px;
}

.module_selected {
  width: 100%;
  text-align: center;
  color: $primarycolor;
  background-color: $primarycolor_background;
  border: 1px solid $primarycolor;
  border-radius: 2px;
}

.custom_textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(240, 240, 240);
}

.k-textarea:focus {
  width: 100%;
  border-radius: 5px;
  border: 1px solid $primarycolor;
  box-shadow: 0 0 0 1px $primarycolor;
}

.k-textarea:hover {
  width: 100%;
  border-radius: 5px;
  border: 1px solid $primarycolor;
}

.k-textarea:focus-within {
  width: 100%;
  border-radius: 5px;
  border: 1px solid $primarycolor;
  box-shadow: 0 0 0 1px $primarycolor;
}

.alert-div {
  background: #009BCE;
  color: white;
  padding: 7px;
  border-radius: 5px;
}

.cellule_Bound span,
.cellule_Déversé span,
.complete_message {
  padding: 6px 10px;
  background-color: $validationcolor;
  border-radius: 13px;
  color: white;
}

.cellule_Aucun span {
  padding: 6px 10px;
  background-color: lightgrey;
  border-radius: 13px;
  color: white;
}

.column_Deversement\.Estimate {
  >span {
    border-radius: 5px;
  }

  &.ValueCell_AgencyValidated {
    @extend .status_message_base;
    background-color: $informationcolor;
  }

  &.ValueCell_ClientValidated {
    @extend .status_message_base;
    background-color: $validationcolor;
  }

  &.ValueCell_Canceled {
    @extend .status_message_base;
    background-color: $errorcolor;
  }

  &.ValueCell_Archived {
    @extend .status_message_base;
    background-color: lightgrey;
  }

  &.ValueCell_Draft {
    @extend .status_message_base;
    background-color: lightgrey;
  }

  &.ValueCell_Valuation {
    @extend .status_message_base;
    background-color: $modificationcolor;
  }
}

.incomplete_message {
  padding: 6px 10px;
  background-color: $errorcolor;
  border-radius: 13px;
  color: white;
}



.loading-text-container {
  border-radius: 13px;
  padding: 5px 20px 5px 20px;
  background: #f7f7f7;
  text-align: center;
}

.loading-text {
  margin-top: 5px;
  margin-bottom: 5px;
}

.loader-json {
  width: 100px;
  height: 100px;
  overflow: hidden;
  z-index: 1000;
}

.is_favorite {
  color: $primarycolor;
}

.is_not_favorite {
  color: $grey-border;
}

.primary_title {
  color: $primarycolor;
}

.qualityScore {
  margin-right: 8px;
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.check_price {
  margin-top: 7px;
  margin-right: 8px;
  float: left;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.check_price_badge {
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.has_price {
  background: $validationcolor;
}

.has_no_price {
  background: $bordercolor;
}

.import_cell_incomplete {
  color: $errorcolor
}

.text_import_cell_incomplete {
  margin-left: 10px;
}

.daterangepicker_selector {
  width: 100%;
}

.k-daterangepicker-wrap .k-textbox-container {
  width: calc(50% + 6px);
}

.content-simpledatepicker {
  .k-textbox-container {
    width: 100%;
  }
}

.k-daterangepicker-wrap :nth-child(2) {
  float: right;
  width: calc(50% - 24px);
}

.adw-title-base,
.adw-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.adw-title {
  margin-bottom: 20px;
  width: 100%;
}

.message_details_leftcombo,
.left_column {
  padding: 0 10px 0 20px;
}

.message_details_rightcombo,
.right_column {
  padding: 0 20px 0 10px;
}

.message_details_allcombo {
  padding: 0 10px;
  margin-bottom: 20px;
}

.element_missing_link {
  margin-bottom: 10px;
}

.green_indicator {
  margin: 0 auto;
  width: 15px;
  height: 15px;
  background-color: $validationcolor;
  border-radius: 50%;
}

.red_indicator {
  margin: 0 auto;
  width: 15px;
  height: 15px;
  background-color: $errorcolor;
  border-radius: 50%;
}

th.k-header:hover {
  // overflow: visible;
  // z-index: 1000 !important;
}

.header-indic {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// th.k-header:hover .header-indic {
//   padding-left: 10px;
//   padding-right: 10px;
//   background: white;
//   border-radius: 5px;
//   box-shadow: 1px 1px 2px 1px #a1a1a1;
//   max-width: auto !important;
// }

.custom-TextComponent:hover {}

span.k-column-resizer {
  //z-index: -1;
}

.title-style-text-scheduler {
  margin-bottom: 5px;
  font-weight: 500;
}


.title-style-text-scheduler.selected-element-category {
  margin-left: 20px;
  margin-top: 50px;
  font-family: 'Rubik' !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 0px !important;
}

.k-treeview .k-i-expand+.k-checkbox-wrapper {
  margin-left: 0px !important;
}

#diffusion_dialog {
  max-width: 800px !important;
  min-width: 800px !important;
}

.k-calendar-range .k-calendar-view {
  height: auto !important;
}

.k-pending-focus {
  background: rgb(218, 218, 218);
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-element {
  animation: rotating 2s linear infinite;
}

.k-calendar-view .k-link {
  width: auto !important;
  height: auto !important;
  text-align: center;
}

.k-calendar-weekdays th {
  width: 50px !important;
  text-align: center;
}

.k-grid-header .k-header>.k-link,
.k-header>.k-cell-inner,
.k-cell-inner>.k-link {
  margin: 0;
  padding: 0;
}

.item-link {
  width: calc(100% - 100px);
}

.vertexauto-option,
.drop-down-tooltip-item,
.item-link {
  overflow: hidden;
  display: block !important;
}

.container-overflow-hide,
.text-overflow-hide {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.k-grid-table td,
.vertexauto-option,
.item-link,
.drop-down-tooltip-item {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vertexauto-option-favorite {
  padding-right: 0px;
}

li.MuiAutocomplete-option.vertexauto-option,
.MuiListSubheader-root {
  padding-right: 2px;
  padding-left: 10px !important;
  overflow: visible;
}

// .k-grid-table td:hover, .vertexauto-option:hover {
//   white-space: normal;
//   word-break: break-all;
// }

.vertexauto-option:hover {
  background: rgb(240, 240, 240) !important;
  height: auto !important;
  z-index: 100;
}

li.drop-down-tooltip-item {
  white-space: nowrap !important;
}

.MuiAutocomplete-listbox,
li.drop-down-tooltip-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tooltip-adw {
  z-index: 20000;
  background: #353535d3;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 2px;
  max-width: 550px;
  overflow-wrap: break-word;
  transition: visibility 0s, opacity 0.8s linear;
}

.adw-form-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.adw-form-title div {
  color: $primarycolor !important;
  width: 30px;
}

.adw-form-action {
  display: flex;
  justify-content: flex-end;

  .cancel {
    margin-right: $mainMargin !important;
  }
}

.k-calendar-td,
.k-calendar-th {
  padding: 0px !important;
}

.navigation_container {
  header.appBarShift {
    z-index: 11;
  }
}

.k-list {
  li.k-first.custom-item-group {
    position: relative !important;
  }

  .k-list-item {
    padding: 4px 8px;
  }
}

.configuration-multiselect {
  &.unlock {
    .k-chip-remove-action {
      display: block;
    }
  }

  .k-chip-remove-action {
    display: none;
  }

  .k-clear-value {
    display: none !important;
  }

  .k-input {
    border-style: none;
    border-color: #009BCE !important;
  }

  .k-input-solid:focus {
    border-color: #009BCE !important;
  }

  .k-input-solid {
    background-color: transparent !important;
  }

  .k-chip-solid-base {
    border-radius: 8px;
    vertical-align: middle;
    justify-content: center;
    font-size: $mainFontSize;
    font-weight: 400;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-color: #009BCE !important;
    color: #009BCE !important;
    height: 18px !important;
    margin-right: 6px;
    margin-bottom: 0;
    margin-left: 0;
    background-color: transparent !important;
  }

  &.margin-top .k-chip-solid-base {
    margin-top: 6px;
  }

  button.k-button {
    font-size: 0.8125rem;
    padding: 0px 4px;
    justify-content: center;
    vertical-align: middle;
    font-weight: 400;
    margin-right: 6px;
    margin-bottom: 0;
    margin-left: 0;
    font-weight: 500;
    height: 24px !important;
    border: none;
  }
}

.configuration-container {
  .k-treeview-leaf {
    padding: 8px 4px;
  }

  .k-treeview-leaf-text {
    font-size: $mainFontSize;
    font-family: $mainFontNumber !important;
  }

  .k-treeview {
    max-height: 500px;
  }

  .k-input-solid {
    background-color: transparent;
    border-top-width: 0px;
  }

  .k-input-solid:focus {
    border-color: #009BCE !important;
  }

  .k-input-solid:focus-within {
    border-color: #009BCE !important;
  }
}

.configuration-slider {
  float: left;
  width: 20px;
  height: 100%;
  margin-top: $mainMargin/2;

  .k-slider-track {
    background-color: $dark-grey;
  }

  .k-draghandle {
    background-color: $dark-blue;
    border-color: $dark-blue;
  }

  .k-slider-selection {
    background-color: $dark-blue;
  }
}


.k-expander {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  line-height: 1;
  font-family: $mainFont;
  font-weight: 400;

  .k-spacer {
    display: none;
  }

  .k-expander-title {
    color: $menu-color;
    text-transform: none;
    position: absolute;
    top: 0;
    left: $mainMargin;
    font-size: 11.9px;
    font-weight: 400;
    background-color: white;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -5px;
  }

  .k-expander-indicator {
    position: absolute;
    right: 12px;
  }

  .k-expander-sub-title {
    white-space: nowrap;
    width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: small;
    padding-bottom: 2px;
  }

  .k-expander-header {
    padding: $mainMargin;
  }

  .k-expander-content {
    padding: 0 $mainMargin;
  }
}

.k-expanded {
  .k-expander-title {
    color: $primarycolor;
  }
}

.k-expander.k-expanded {
  border: 2px solid $primarycolor;
}

.k-expander.k-focus {
  box-shadow: none;
}

.k-pivotgrid-configurator-panel {
  border: 0px;
  width: 100%;

  .k-actions {
    padding: 0px;
  }

  .k-treeview-md {
    font-size: $mainFontSize;
    line-height: 1;
  }

  .k-treeview-md {
    padding: 0px;
  }
}

.k-pivotgrid-configurator-header {
  display: none;
}

.k-pivotgrid-configurator-content {
  padding: 0px;

  .k-fields-list-wrapper {
    max-height: 400px;
  }
}

.k-editor>.k-toolbar {
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.23);
  padding: 0 0.5rem;
}

.k-toolbar {
  box-shadow: none;

  .k-button-solid-base {
    background-color: transparent;
  }

  .k-input-inner {
    font-size: 1rem;
  }
}

.k-input {
  line-height: normal !important;
}

.k-input-inner,
input.k-input,
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner,
.daterangepicker_selector {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.custom-multiselect {
  .k-input-loading-icon {
    width: 24px;
    height: 24px;
  }
}

.k-input-solid:focus-within,
.k-picker-solid:focus-within {
  border-color: $primarycolor;
}

.k-picker-solid {
  background-color: transparent;
}

.k-icon-button.k-button-md {
  padding: 2px;
}

.k-list-filter {
  padding: $mainPadding / 2;
}

.k-input-md .k-input-prefix>.k-icon {
  width: 16px;
  height: 16px;
}

.website-textbox {
  .k-input-inner {
    padding: 0px;
  }

  .k-input-prefix {
    padding-left: $mainPadding;
  }

  .k-button {
    padding: 4px;
  }
}

.accordion {
  margin-top: 16px !important;
  float: left;
  width: 100%;

  .accordion-summary {
    margin: 0 8px !important;
    border: 1px solid $grey-border !important;
    border-radius: 4px !important;

    &.expanded {
      border-bottom: none !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      &.selected {
        .title {
          transition: all 0.5s ease-in-out;
          transform: translate(0px, -28px) !important;
          background-color: white !important;
          padding: 0px 4px;

          p {
            transition: all 0.5s ease-in-out;
            font-size: 11.9px !important;
          }
        }

        .selected-items-container {
          transition: all 0.5s ease-out 0.3s;
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .MuiAccordionSummary-content {
      align-items: center;
      margin: 8px 0 !important;
      min-height: 40px;

      &.Mui-expanded {
        margin: 8px 0 !important;
      }
    }

    .title {
      transition: all 0.5s ease-in-out 0.3s;

      p {
        transition: all 0.5s ease-in-out 0.3s;
      }

      span {
        margin-right: 5px;
        font-weight: bold;
      }
    }

    .selected-items-container {
      transition: all 0.5s ease-in;
      opacity: 0;
      visibility: collapse;
      display: flex;
      align-items: center;
      padding-top: 8px;
    }
  }
}

.MuiAccordion-root:before {
  display: none !important;
}

.accordion-details {
  max-width: 100%;
  overflow: auto;
  padding: 0px 8px !important;

  .custom-toolbar-adwtelerikgrid {
    margin: 0;
    max-width: 100%;
  }

  div {
    max-width: 100%;
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.scheduler-legend {

  display: flex;
  align-items: center;
  float: left;

  /* Pour éviter le chevauchement avec le total elements */
  max-width: calc(100% - 100px);
  overflow: hidden;

  .legend-group {

    cursor: pointer;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: $mainMargin;

    .badge-template {
      margin-right: 5px;
    }
  }

  .badge-template {
    position: relative;
    width: 13px !important;
    height: 13px !important;

    >span.k-badge-edge.k-badge-md {
      border-radius: 0%;
      border: 0px;
      width: 13px !important;
      height: 13px !important;
      transform: unset;
    }
  }

  .label-element-legend {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    max-width: 135px;
  }

  .legend-checked {
    color: transparent;
    position: absolute;
    left: 1px;
    top: 1px;
    z-index: 100;

    >span {
      font-size: 11px;
      font-weight: bolder;
    }
  }
}