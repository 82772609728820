
@import './colors.scss';
@import './theme.scss';

li.k-item.k-active {
    > span.k-link {
        font-weight: 500;
        color: $dark-blue;
        background-color: $light-blue;
        border-bottom: solid 2px $dark-blue;
    }
}

.k-tabstrip > .k-content {
    padding: $mainMargin 0px;
}

.k-tabstrip.tabpanel_rightmenu {
    >.k-content {
        padding-left: $mainMargin;
        padding-right: 52px;
    }
}

.k-tabstrip-items-wrapper.k-hstack {
    overflow: hidden;
    > ul.k-tabstrip-items.k-reset {
        display: block;
        > li.k-item {
            height: $mainHeight;
            float: left;
            > span.k-link {
                padding: 0px $mainMargin;
                text-transform: capitalize;
            }
        }
    }
}

