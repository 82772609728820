@import './colors.scss';
@import './theme.scss';

.navigation{
    &_container{
        display: flex;
    }
    &_drawer{
        width: 250px;
        flex-shrink: 0;
        z-index: 10 !important;
        &_header{
            &_content{
                height: 100%;
                &_title{
                    height: 76px;
                    width: 171px;
                    float: left;
                    text-align: right;
                    padding-top: 6px;
                    font-weight: 500;
                }
                &_button{
                    float: left;
                    margin-top: $mainMargin !important;
                    margin-left: $mainMargin !important;
                }
            }
        }
        &_paper{
            width: 250px;
        }
        &_list{
            max-height: 100vh;
            padding-top: 0px;

            &_item{
                color: $lightblack-color !important;
                &_text{

                    p{

                    }
                }
                &_icon{
                    color: $lightblack-color !important;
                    svg {
                        fill: $lightblack-color;
                    }
                    min-width: 30px !important;
                }
            }
            &_subitem{
                padding-left: 35px !important;
                padding-right: 0px !important;
                color: $lightblack-color !important;
            }
        }
    }
}

.MuiListItem-button.active *{
    color: $primarycolor;
    svg {
        fill: $primarycolor;
    }
  }
  .MuiListItem-button.active{
    border-right: 2px solid $primarycolor;
    background-color: $primarycolor_background;
  }
  .MuiListItem-button.active:hover{
    background-color: $primarycolor_background;
  }

#language_expand{
    margin-left: 0px !important;
    &:hover{
        background-color: transparent !important;
    }
}
#settings_expand{
    margin-left: 0px !important;
    margin-right: 0px !important;
    &:hover{
        background-color: transparent !important;
    }
}
.user_menu{
    width: 194 !important;
    margin-top: 2.5% !important;
    &_avatar{
        background-color: white !important;
        color: $primarycolor !important;
        border: 1px solid $primarycolor !important;
    }
    &_icon_primary{
        min-width: 24px !important;
        margin-right: 15px !important;
        color: #5C5C5C !important;
    }
    &_text_primary{
        color: #5C5C5C !important;
        font-family: 'Rubik' !important;
        font-weight: normal !important;
    }
    &_icon_error{
        min-width: 24px !important;
        margin-right: 15px !important;
        color: $errorcolor!important;
    }
    &_text_error{
        color: $errorcolor!important;
        font-family: 'Rubik' !important;
        font-weight: normal !important;
    }
    &__divider{
        width: 80%!important;
        margin: 2px auto !important;
    }

}